import { datadogLogs } from '@datadog/browser-logs'

import {
  ChannelConditions,
  ChannelValue,
  GetCookieArg,
} from './shared-customer-journey.types'

export const getParamValue = (param: string): string | null => {
  if (!window.location) return null

  const params = window.location?.search
  const searchParams = new URLSearchParams(params)

  return searchParams.get(param)
}

export const getChannel = (): ChannelValue => {
  if (!window.location) return ChannelValue.direct

  const params = window.location.search
  datadogLogs.logger.info('AWIN URL search params', {
    location: window.location,
    params: params,
  })
  const searchParams = new URLSearchParams(params)

  const awc = searchParams.has('awc')
  const CID = searchParams.get('CID')?.toLowerCase()
  const gclid = searchParams.has('gclid')
  const fbclid = searchParams.has('fbclid')

  const channelConditionsList: ChannelConditions[] = [
    {
      priority: 1,
      channelName: 'ppcGeneric',
      condition: gclid && !CID?.includes('brand'),
      channelValue: ChannelValue.ppcGeneric,
    },
    {
      priority: 2,
      channelName: 'ppcBrand',
      condition: gclid && !!CID?.includes('brand'),
      channelValue: ChannelValue.ppcBrand,
    },
    {
      priority: 3,
      channelName: 'facebook',
      condition:
        fbclid &&
        !!CID?.includes('social') &&
        (CID.includes('meta') || CID.includes('facebook')),
      channelValue: ChannelValue.facebook,
    },
    {
      priority: 4,
      channelName: 'tiktok',
      condition: !!CID?.includes('tiktok'),
      channelValue: ChannelValue.tiktok,
    },
    {
      priority: 5,
      channelName: 'awin',
      condition: awc,
      channelValue: ChannelValue.awin,
    },
    {
      priority: 6,
      channelName: 'direct',
      condition: !CID && !awc,
      channelValue: ChannelValue.direct,
    },
  ]

  const channelConditionValues = channelConditionsList.filter(
    (channelCondition) => channelCondition.condition,
  )

  return channelConditionValues.length > 0
    ? channelConditionValues.sort((a, b) => a.priority - b.priority)[0]
        .channelValue
    : ChannelValue.direct
}

export const getCookie = ({ name }: GetCookieArg): string | undefined =>
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop()

export const cookieBuilder = (
  key: string,
  data: Record<string, unknown>,
  expiryDay: number,
): string => {
  datadogLogs.logger.info('AWIN cookie data', { data: data })

  const expiryDate: Date = new Date()
  const cookieValue = JSON.stringify(data)
  const daysToMilliseconds = expiryDay * 8.64e7

  expiryDate.setTime(expiryDate.getTime() + daysToMilliseconds)
  const expires = `expires=${expiryDate.toUTCString()}`

  return `${key}=${cookieValue};${expires};path=/`
}

// Will only work for objects that are one level deep
export const compareObjects = (
  object1: Record<string, unknown>,
  object2: Record<string, unknown>,
) => {
  for (const key in object1) {
    // eslint-disable-next-line no-prototype-builtins
    if (object1.hasOwnProperty(key) && object1[key] !== object2[key]) {
      return false
    }
  }
  for (const key in object2) {
    // eslint-disable-next-line no-prototype-builtins
    if (object2.hasOwnProperty(key) && object1[key] !== object2[key]) {
      return false
    }
  }
  return true
}
