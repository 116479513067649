import { Secondary, Text } from '@cinch-labs/design-system'
import { useViewport } from '@cinch-labs/shared-util'
import { StoryblokImage } from '@cinch-nx/data-storyblok'
import {
  EventNames,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import Image from 'next/image'

import styles from './help-me-choose.module.css'

export interface HelpMeChooseProps {
  title: string
  heading: string
  body: string
  ctaText: string
  image: StoryblokImage
}

const HelpMeChoose: React.FC<HelpMeChooseProps> = ({
  title,
  heading,
  body,
  ctaText,
  image,
}) => {
  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })
  const isMobile = vw < 1024

  const { trackEvent } = useAnalytics()

  const handleClick = () => {
    trackEvent({
      eventName: `Help me choose`,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: EventNames.click,
          category: 'button',
          label: 'Help me choose quiz banner',
          action: 'Help me choose',
        },
      },
    })
  }

  return (
    <>
      <Text element="h2" className={styles['title']}>
        {title}
      </Text>
      <section className={styles['container']}>
        <div className={styles['leftSection']}>
          <div>
            <Text element="h3" className={styles['heading']}>
              {heading}
            </Text>
            <Text element="p" className={styles['body']}>
              {body}
            </Text>

            <Secondary
              data-testid="help-me-choose-button"
              classNames={{ button: styles['buttonContainer'] }}
              type="submit"
              variant="alternative"
              element="a"
              href="/help-me-choose/most-important"
              onClick={handleClick}
            >
              {ctaText}
            </Secondary>
          </div>
        </div>
        {isMobile ? null : (
          <div className={styles['image']}>
            <Image
              src={image.filename}
              alt={image.alt ?? ''}
              layout="responsive"
              width={408}
              height={265}
            />
          </div>
        )}
      </section>
    </>
  )
}

export default HelpMeChoose
