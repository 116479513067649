import {
  CarouselControls,
  CarouselNavigation,
  CarouselPagination,
  CarouselSlide,
  Carousel as DSCarousel,
} from '@cinch-labs/design-system'
import type { Vehicle } from '@cinch-nx/shared-types'

import { FindSimilarVehiclesCard, VehicleCard } from '../cards'
import styles from './carousel.module.css'

interface CarouselProps {
  carouselName: string
  slidesPerView: number
  vehicles: Vehicle[]
}

export const Carousel: React.FC<CarouselProps> = ({
  carouselName,
  slidesPerView,
  vehicles,
}) => {
  const navAriaLabels = {
    previous: 'Go to previous slide',
    next: 'Go to next slide',
  }

  const swiperSettings = {
    spaceBetween: 16,
    slidesPerView,
    cssMode: true,
    effect: 'cards',
  }
  return (
    <>
      <DSCarousel
        name={carouselName}
        swiperSettings={swiperSettings}
        ariaLabel={`${carouselName}-carousel`}
      >
        {vehicles.map((vehicle) => {
          return (
            <CarouselSlide key={vehicle.vehicleId}>
              <VehicleCard vehicle={vehicle} carouselName={carouselName} />
            </CarouselSlide>
          )
        })}
        {vehicles.length <= 2 ? (
          <CarouselSlide key="cta-card">
            <FindSimilarVehiclesCard />
          </CarouselSlide>
        ) : null}
      </DSCarousel>
      <CarouselControls customClassNames={styles['controlsWrapper']}>
        <CarouselPagination
          name={carouselName}
          customClassNames={styles['customPagination']}
        />
        <CarouselNavigation
          name={carouselName}
          ariaLabels={navAriaLabels}
          customClassNames={{
            prevButton: styles['prevButton'],
            nextButton: styles['nextButton'],
          }}
        />
      </CarouselControls>
    </>
  )
}
