import {
  EventNames,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import cx from 'classnames'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

import styles from './tabs.module.css'

interface TabsContextProps {
  activeTab: number
  setActiveTab: Dispatch<SetStateAction<number>>
}

const TabsContext = createContext<TabsContextProps>({
  activeTab: 1,
  setActiveTab: () => null,
})

export const useTabs = () => useContext(TabsContext)

interface TabsProps {
  children: React.ReactNode
}

export const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<number>(1)
  return (
    <TabsContext.Provider value={{ activeTab, setActiveTab }}>
      <div className={styles['tabsWrapper']}>{children}</div>
    </TabsContext.Provider>
  )
}

interface TabButtonProps {
  tabOrderNumber: 1 | 2
  children: React.ReactNode
}

export const TabButton: React.FC<TabButtonProps> = ({
  tabOrderNumber,
  children,
}) => {
  const { activeTab, setActiveTab } = useTabs()
  const isActive = activeTab === tabOrderNumber

  const { trackEvent } = useAnalytics()

  const handleClick = (tabOrderNumber: number) => {
    trackEvent({
      eventName: `Personalised Component`,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: EventNames.click,
          category: 'Personalised Component',
          label:
            tabOrderNumber === 1 ? 'Recently Viewed tab' : 'You Might Like tab',
          action: 'Tab Clicked',
        },
      },
    })
    setActiveTab(tabOrderNumber)
  }

  return (
    <button
      id={`tab-${tabOrderNumber}`}
      type="button"
      role="tab"
      className={cx(styles['tabButton'], {
        [styles.tabButtonLeft]: tabOrderNumber === 1,
        [styles.tabButtonRight]: tabOrderNumber === 2,
      })}
      aria-selected={isActive}
      aria-controls={`tabpanel-${tabOrderNumber}`}
      tabIndex={0}
      onClick={() => handleClick(tabOrderNumber)}
    >
      {children}
    </button>
  )
}

interface TabPanelProps {
  tabOrderNumber: 1 | 2
  children: React.ReactNode
}

export const TabPanel: React.FC<TabPanelProps> = ({
  tabOrderNumber,
  children,
}) => {
  const { activeTab } = useTabs()

  const isActiveTab = activeTab === tabOrderNumber

  return (
    <div
      id={`tabpanel-${tabOrderNumber}`}
      role="tabpanel"
      tabIndex={isActiveTab ? 0 : -1}
      aria-labelledby={`tab-${tabOrderNumber}`}
      className={cx(styles['tabPanel'], {
        [styles.tabPanelIsHidden]: !isActiveTab,
        [styles.tabPanelLeft]: activeTab === 1,
        [styles.tabPanelRight]: activeTab === 2,
      })}
    >
      {isActiveTab ? children : null}
    </div>
  )
}

interface TabsButtonListProps {
  children: React.ReactNode
}

export const TabButtonList: React.FC<TabsButtonListProps> = ({ children }) => (
  <div
    role="tablist"
    aria-labelledby="tablist-1"
    className={styles['tabButtonList']}
  >
    {children}
  </div>
)
