import { getReviews } from '@cinch-nx/data-trustpilot'
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'

import { getHomePageContentData } from './data/content'
import { getHomePageVehiclesData } from './data/vehicles'
import { getPersonalisationEligibility } from './helpers/get-personalisation-eligibility'

export const getServerSideProps = async ({
  preview = false,
  req,
}: GetServerSidePropsContext) => {
  const vehiclesData = await getHomePageVehiclesData()
  const story = await getHomePageContentData(preview)
  const trustpilotData = await getReviews()

  if (!story) {
    throw new Error('Unable to fetch `home` story from storyblok')
  }

  try {
    const {
      showPersonalisedComponents,
      similarListings,
      recentlyViewedListings,
    } = await getPersonalisationEligibility(req.cookies)

    return {
      props: {
        story,
        vehiclesData,
        showPersonalisedComponents,
        trustpilotData,
        preview,
        recentlyViewedListings,
        similarListings,
        generatedAt: Date.now().toString(),
        layoutProps: {},
      },
    }
  } catch (error) {
    // TODO: address this when we upgrade next so we can send logs to datadog
    console.log(
      'there is an error with the getPersonalisationEligibility call',
      error,
    )

    return {
      props: {
        story,
        preview,
        vehiclesData,
        showPersonalisedComponents: false,
        trustpilotData,
        recentlyViewedListings: null,
        similarListings: null,
        generatedAt: Date.now().toString(),
        layoutProps: {},
      },
    }
  }
}

export type HomepageProps = InferGetServerSidePropsType<
  typeof getServerSideProps
>
