import { Text, Youtube } from '@cinch-labs/design-system'
import {
  BLOK_YOUTUBE,
  StoryBlokRichText,
  isYoutubeBlok,
} from '@cinch-nx/data-storyblok'
import {
  MARK_BOLD,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  render,
} from 'storyblok-rich-text-react-renderer'

import styles from './rich-text-renderer.module.css'

const resolveYoutubeVideo = (props: Record<string, unknown>) =>
  isYoutubeBlok(props) ? (
    <Youtube
      {...props}
      className={styles.video}
      customThumbnail={props.thumbnail.filename}
    />
  ) : null

const renderWithResolver = (
  body: StoryBlokRichText,
  makeId?: boolean,
): React.ReactNode =>
  render(body, {
    nodeResolvers: {
      [NODE_IMAGE]: (_, props) => (
        <img {...props} style={{ height: 'auto' }} alt={props.alt} />
      ),
      [NODE_PARAGRAPH]: (children) =>
        children ? (
          <Text className={styles.rtParagraph}>{children}</Text>
        ) : null,
    },
    blokResolvers: {
      [BLOK_YOUTUBE]: (props) => resolveYoutubeVideo(props),
    },

    ...(makeId && {
      markResolvers: {
        [MARK_BOLD]: (children) => (
          <strong id={children?.toLocaleString().replace(/\s/g, '')}>
            {children}
          </strong>
        ),
      },
    }),
  })

export default renderWithResolver
