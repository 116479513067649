import { Field, FieldProps } from 'react-final-form'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FieldWrapper: React.FC<FieldProps<string, any>> = ({
  name,
  onBlur,
  onClick,
  ...props
}) => {
  const blurable = props.type !== 'checkbox' && props.type !== 'radio'

  const wrappedOnClick = (e: Event) => {
    if (onClick) onClick(e)
    if (!blurable) {
      return name
    }
    return
  }

  const wrappedOnBlur = (e: Event) => {
    if (onBlur) onBlur(e)
    if (e.target && blurable) {
      return name
    }

    return
  }

  return (
    <Field
      name={name}
      onBlur={wrappedOnBlur}
      onClick={wrappedOnClick}
      {...props}
    />
  )
}

export default FieldWrapper
