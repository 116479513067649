import { useViewport } from '@cinch-labs/shared-util'
import { Vehicle } from '@cinch-nx/shared-types'

import { VehicleCardsList } from './cards'
import { Carousel } from './carousel/carousel'
import { TabButton, TabButtonList, TabPanel, Tabs } from './tabs/tabs'

interface RecentAndRecommendedVehiclesProps {
  recentlyViewedListings?: Vehicle[]
  similarListings?: Vehicle[]
}

export const RecentAndRecommendedVehicles: React.FC<
  RecentAndRecommendedVehiclesProps
> = ({ recentlyViewedListings, similarListings }) => {
  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })

  const isDesktop = vw >= 1024
  const isTablet = vw >= 768 && vw < 1024
  const isMobile = vw < 768

  const hasVehicleListingsData =
    recentlyViewedListings &&
    recentlyViewedListings?.length >= 1 &&
    similarListings &&
    similarListings?.length >= 1

  if (hasVehicleListingsData) {
    const showRecentlyViewedCarousel =
      isMobile ||
      (isTablet && recentlyViewedListings?.length >= 2) ||
      (isDesktop && recentlyViewedListings?.length >= 4)

    const slidesPerView = isMobile ? 1 : isTablet ? 2 : 3

    return (
      <Tabs>
        <TabButtonList>
          <TabButton tabOrderNumber={1}>Recently viewed</TabButton>
          <TabButton tabOrderNumber={2}>You might like</TabButton>
        </TabButtonList>
        <TabPanel tabOrderNumber={1}>
          {showRecentlyViewedCarousel ? (
            <Carousel
              slidesPerView={slidesPerView}
              carouselName="recently-viewed-cars"
              vehicles={recentlyViewedListings}
            />
          ) : (
            <VehicleCardsList
              vehicles={recentlyViewedListings}
              carouselName="recently-viewed-cars"
            />
          )}
        </TabPanel>
        <TabPanel tabOrderNumber={2}>
          <Carousel
            slidesPerView={slidesPerView}
            carouselName="you-might-like"
            vehicles={similarListings}
          />
        </TabPanel>
      </Tabs>
    )
  } else {
    return null
  }
}
