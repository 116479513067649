import { Vehicle } from '@cinch-nx/shared-types'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { getRecentAndRecommendVehicles } from '../data/get-recent-and-recommend-vehicles'

export interface GetPersonalisationEligibilityArgs {
  showPersonalisedComponents: boolean
  recentlyViewedListings: Vehicle[]
  similarListings: Vehicle[]
}

export const getPersonalisationEligibility = async (
  cookies: NextApiRequestCookies,
): Promise<GetPersonalisationEligibilityArgs> => {
  const vehicleIds = cookies['cinch-recently-viewed-cars']
  const cookiePreferences = cookies['cp'] ? JSON.parse(cookies['cp']) : null
  const hasFunctionalCookies = cookies && cookiePreferences?.functional

  const parsedVehicleIds: string[] = vehicleIds ? JSON.parse(vehicleIds) : []

  const vehicleIdsAsAnArray =
    parsedVehicleIds.length > 0 ? parsedVehicleIds : []

  let recentlyViewedListings: Vehicle[] = []
  let similarListings: Vehicle[] = []

  try {
    if (vehicleIdsAsAnArray.length > 0) {
      const data = await getRecentAndRecommendVehicles(vehicleIdsAsAnArray)
      recentlyViewedListings = data.recentlyViewedListings
      similarListings = data.similarListings
    }
  } catch (error) {
    // TODO: address this when we upgrade next so we can send logs to datadog
    console.log(
      'there is an error with the getRecentAndRecommendVehicles call',
      error,
    )
  }

  const hasPreviouslyViewedCars =
    vehicleIdsAsAnArray !== undefined &&
    recentlyViewedListings.length > 0 &&
    similarListings.length > 0

  const showPersonalisedComponents =
    (hasPreviouslyViewedCars && hasFunctionalCookies) ?? false

  return {
    showPersonalisedComponents,
    recentlyViewedListings,
    similarListings,
  }
}
