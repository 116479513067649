export type GetCookieArg = Record<'name', 'cp' | 'awin'>

export type Cookie = {
  commissionGroup: string
  channel: string
  awc: string | null
  voucher: string
}

export type CustomerJourneyCookieResult = {
  data: Cookie | null
  message: string
}

export type ClearCustomerJourneyCookieResult = {
  message: string
}

export enum ChannelValue {
  awin = 'aw',
  ppcBrand = 'aw',
  ppcGeneric = 'ppc',
  facebook = 'fb',
  tiktok = 'tiktok',
  direct = 'aw',
}

export type ChannelKeys =
  | 'awin'
  | 'ppcBrand'
  | 'ppcGeneric'
  | 'facebook'
  | 'tiktok'
  | 'direct'

export type ChannelConditions = {
  channelName: ChannelKeys
  priority: number
  condition: boolean
  channelValue: ChannelValue
}
