import { saveCustomerJourneyCookie } from '@cinch-labs/customer-journey-cookies'
import { Heading } from '@cinch-labs/design-system'
import { useViewport } from '@cinch-labs/shared-util'
import { useStoryblokState } from '@cinch-nx/data-storyblok'
import Head from '@cinch-nx/landing-ui-shared/lib/head/head'
import { StoryblokRenderer } from '@cinch-nx/landing-ui-shared/lib/storyblok-renderer/storyblok-renderer'
import { ModalChat } from '@cinch-nx/shared-ui/lib/modal-chat/modal-chat'
import TrustpilotReviews from '@cinch-nx/shared-ui/lib/trustpilot-reviews/trustpilot-reviews'
import Wrapper from '@cinch-nx/shared-ui/lib/wrapper/wrapper'

import {
  OurCommitmentCarousel,
  RecentAndRecommendedVehicles,
  ShopByShape,
  WeBuyAnyCar,
} from './components'
import { storyblokComponents } from './data/storyblok-renderer-map'
import type { HomepageProps } from './get-server-side-props'
import styles from './homepage.module.css'
import homePage from './locale/index'

export interface FieldOption {
  count: string
  label: string
  value: string
}

export const LandingFeatureHomePage = ({
  preview,
  vehiclesData,
  trustpilotData,
  story,
  showPersonalisedComponents,
  recentlyViewedListings,
  similarListings,
}: HomepageProps) => {
  story = useStoryblokState({ story, enableBridge: preview })
  const { ourCommitmentSlides, wbac, trustpilotReviews } = homePage

  saveCustomerJourneyCookie()

  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })

  const isMobile = vw < 600

  return (
    <>
      <Head
        {...story}
        fallbackTitle="Cars Without The Faff - cinch"
        fallbackDescription="Welcome to cinch, the faff free way to buy & sell cars. We have thousands of used cars, ready to be sold to you."
      />

      <StoryblokRenderer
        block={story.content.hero}
        components={storyblokComponents}
        props={{
          makes: vehiclesData.makes,
          totalStock: vehiclesData.totalStock,
          starRating: trustpilotData.stars,
          starRatingLabel: trustpilotData.starRatingLabel,
        }}
      />

      {showPersonalisedComponents ? (
        <Wrapper>
          <Heading element="h2" className={styles['heading']}>
            Welcome back
          </Heading>
          <RecentAndRecommendedVehicles
            recentlyViewedListings={recentlyViewedListings ?? []}
            similarListings={similarListings ?? []}
          />
          <div className={styles['valuePropsCardsContainer']}>
            <StoryblokRenderer
              block={story.content.personalised[0].valuePropositions}
              components={storyblokComponents}
            />
          </div>
          <StoryblokRenderer
            block={story.content.personalised[0].helpMeChoose}
            components={storyblokComponents}
          />
        </Wrapper>
      ) : null}

      <ShopByShape count={vehiclesData.carTypes} />
      {!showPersonalisedComponents ? (
        <StoryblokRenderer
          block={story.content.whyCinch}
          components={storyblokComponents}
        />
      ) : null}

      <StoryblokRenderer
        block={story.content.partEx}
        components={storyblokComponents}
      />
      <TrustpilotReviews
        title={trustpilotReviews.title}
        starRatingLabel={trustpilotData.starRatingLabel}
        numberOfReviews={trustpilotData.total}
        reviews={trustpilotData.reviews}
      />
      {!showPersonalisedComponents ? (
        <OurCommitmentCarousel {...ourCommitmentSlides} />
      ) : null}

      <WeBuyAnyCar {...wbac} />

      {isMobile ? <ModalChat /> : null}
    </>
  )
}

export default LandingFeatureHomePage
