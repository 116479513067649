import { Heading, Secondary } from '@cinch-labs/design-system'
import { useViewport } from '@cinch-labs/shared-util'
import {
  EventNames,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import Image from 'next/image'
import Link from 'next/link'

import type { CarShapes as ShopByShapeCardProps } from '../shop-by-shape-component'
import styles from './shop-by-shape-card.module.css'

const ShopByShapeCard = ({
  title,
  image,
  count,
  minSeats,
  maxSeats,
  filterQuery,
  alt,
}: ShopByShapeCardProps) => {
  const { trackEvent } = useAnalytics()

  const handleClick = () => {
    trackEvent({
      eventName: `Shop by type: ${title}`,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: EventNames.click,
          category: 'button',
          label: 'Shop by type',
          action: title.toLowerCase(),
        },
      },
    })
  }

  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })
  const isMobile = vw < 1024

  return (
    <div data-testid="shop-by-shape-card" className={styles['card']}>
      <Image
        src={image}
        layout="responsive"
        width={248}
        height={115}
        alt={alt}
      />
      <div className={styles['heading']}>
        <Heading element="h4" className={styles['title']}>
          {title}
        </Heading>
        <p className={styles['body']}>{`${minSeats} - ${maxSeats} seats`}</p>
      </div>

      {isMobile ? (
        <div className={styles['link']} onClick={handleClick}>
          <Link href={`/find-vehicle?${filterQuery}`} passHref>
            {`Shop ${count} cars`}
          </Link>
        </div>
      ) : (
        <Secondary
          element="a"
          href={`/find-vehicle?${filterQuery}`}
          classNames={{ button: styles['button'] }}
          onClick={handleClick}
          aria-label="Shop by shape card"
        >
          {`Shop ${count} cars`}
        </Secondary>
      )}
    </div>
  )
}
export default ShopByShapeCard
