import { StoryData, getStoryBySlug } from '@cinch-nx/data-storyblok'

import { HomeStoryContent } from '../types/home-story-content'

export const getHomePageContentData = async (
  preview = false,
): Promise<StoryData<HomeStoryContent> | undefined> => {
  const story = await getStoryBySlug<HomeStoryContent>('home', {
    version: preview ? 'draft' : 'published',
  })

  return story ?? undefined
}
