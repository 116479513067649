import { HomePage } from '../types/feature-home-page'

const homePage: HomePage = {
  seo: {
    title: 'Cars Without The Faff',
    description:
      'Welcome to cinch, the faff free way to buy & sell cars. We have thousands of used cars, ready to be sold to you',
  },
  hero: {
    helpMeChoose: {
      mobile: {
        paragraph1: 'Not sure where to start?',
        paragraph2: 'Try our ',
        link: 'Help Me Choose tool',
      },
      desktop: {
        paragraph1: 'Not sure where to start? Try our ',
        link: 'Help Me Choose tool',
      },
    },
  },
  articles: {
    title: 'Articles',
    cta: 'All articles',
  },
  reviews: {
    title: 'Reviews',
    cta: 'All reviews',
  },
  cinchCare: {
    title: 'Worry-free motoring',
    bullets: [
      'Comprehensive lifetime warranty',
      'Servicing by a nationwide provider',
      'The reassurance of RAC breakdown assistance',
      'For less than £35 a month',
    ],
    button: 'Tell me more',
  },
  ourCommitmentSlides: {
    slides: [
      {
        iconName: 'check-circle',
        title: 'cinch quality assured',
        body: 'All cars are carefully reconditioned and have a minimum of 6 months on the MOT and service history.',
      },
      {
        iconName: 'cash',
        title: 'Money-back guarantee',
        body: 'Enjoy your cinch car for <a href="/returns">14 days</a> to make sure it’s right for you. Changed your mind? We’ll collect it and give you a full refund. Totally faff-free.',
      },
      {
        iconName: 'shield-heart',
        title: 'Caring for your car',
        body: 'Our cars come with a 90-day warranty with breakdown assistance, and 3-day drive-away insurance as standard. Add <a href="/cinch-care">cinchCare</a> for lifetime warranty, breakdown assistance and servicing from £34.99 a month.',
      },
    ],
  },
  trustpilotReviews: {
    title: 'The nation is cinching it',
    reviews: [],
  },
  sponsorSection: {
    logoAlt: 'England cricket logo',
    imageAlt: 'England cricket players',
  },
  wbac: {
    body: 'Just looking to sell for now? Try our sister site webuyanycar.',
    cta: 'Get a free car valuation',
  },
}
export default homePage
