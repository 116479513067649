import { Heading, Primary, PrimaryProps } from '@cinch-labs/design-system'
import { TrackingEventTypes, useAnalytics } from '@cinch-nx/shared-analytics'
import Wrapper from '@cinch-nx/shared-ui/lib/wrapper/wrapper'
import React from 'react'

import WBACLogo from '../assets/logo-full.webuyanycar.svg'
import styles from './wbac.module.css'

/**
 * HOW HAS NO ONE USED TARGET=_BLANK BEFORE...
 * this works, and only expands the prop to add target.
 */
const CTALink = Primary as React.ForwardRefExoticComponent<
  PrimaryProps & { target?: string } & React.RefAttributes<
      HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement
    >
>

export interface WBACProps {
  body: string
  cta: string
}

export const TrackingEvents = {
  cta_click: {
    eventName: `We buy any car link`,
    type: TrackingEventTypes.ADOBE,
    data: {
      event: {
        name: 'click',
        category: 'link',
        label: 'We buy any car',
        action: 'We buy any car link click',
      },
    },
  },
}

export const CTAUrl =
  'https://www.webuyanycar.com/?utm_source=cinch%5Fwebsite&utm_medium=button%5Fid%5F123&utm_campaign=sell%5Fyour%5Fcar'

const WeBuyAnyCar: React.FC<WBACProps> = ({ body, cta }) => {
  const { trackEvent } = useAnalytics()

  const sendTrackEvent = () => trackEvent(TrackingEvents.cta_click)

  return (
    <Wrapper
      data-testid="wbac"
      className={{
        inner: styles.innerWrapper,
      }}
    >
      <Heading className={styles.heading}>{body}</Heading>
      <CTALink
        element="a"
        href={CTAUrl}
        onClick={sendTrackEvent}
        classNames={{
          button: styles.cta,
        }}
        target="_blank"
      >
        {cta}
      </CTALink>
      <div className={styles['logoWrapper']}>
        <WBACLogo className={styles['logo']} />
      </div>
    </Wrapper>
  )
}

export default WeBuyAnyCar
