import { Env, readFromEnv } from '@cinch-nx/environments'
import { Vehicle } from '@cinch-nx/shared-types'

const MAX_VEHICLE_LISTINGS = 6
export const SEARCH_URL = readFromEnv(Env.SearchServiceUrl)

export const getRecentAndRecommendVehicles = async (vehicleIds: string[]) => {
  let recentVehicleResults: { vehicleListings: Vehicle[] }
  let similarVehicleResults: { vehicleListings: Vehicle[] }

  try {
    const data = await fetch(
      `${SEARCH_URL}/vehicles?vehicleIds=${vehicleIds.join(
        ',',
      )}&pageSize=${MAX_VEHICLE_LISTINGS}`,
    )

    recentVehicleResults = await data.json()
  } catch {
    throw new Error(
      'Something went wrong with the fetching of vehicle listings',
    )
  }

  try {
    const similarVehicleData = await fetch(
      `${SEARCH_URL}/similar?vehicleId=${vehicleIds[0]}&pageSize=${MAX_VEHICLE_LISTINGS}`,
    )

    similarVehicleResults = await similarVehicleData.json()
  } catch {
    throw new Error(
      'Something went wrong with the fetching of similiar vehicles',
    )
  }

  const vehicleListings =
    recentVehicleResults?.vehicleListings?.filter(
      (vehicle) => !vehicle.isReserved,
    ) || []

  const similarVehicleListing = similarVehicleResults?.vehicleListings || []

  const orderedVehicles: Vehicle[] = vehicleIds.reduce(
    (vehicles, vehicleId) => {
      const vehicleToOrder = vehicleListings.find(
        (recentVehicle) => recentVehicle.vehicleId === vehicleId,
      )

      if (vehicleToOrder) {
        vehicles = [...vehicles, vehicleToOrder]
      }

      return vehicles
    },
    [] as Vehicle[],
  )

  return {
    recentlyViewedListings: orderedVehicles,
    similarListings: similarVehicleListing,
  }
}
