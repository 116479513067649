import { Text } from '@cinch-labs/design-system'
import { StoryblokImage } from '@cinch-nx/data-storyblok'
import { TrustpilotOverview } from '@cinch-nx/landing-ui-shared/lib/trustpilot-overview/trustpilot-overview'
import { TrackingEventTypes, useAnalytics } from '@cinch-nx/shared-analytics'
import cx from 'classnames'
import Image from 'next/image'

import homePageLocales from '../../locale'
import type { Makes } from '../../types/feature-home-page'
import { Filters } from './filters'
import styles from './hero.module.css'

export interface HeroProps {
  image: StoryblokImage
  heading: string
  introFirstLine: string
  totalStock: number
  starRating: number
  starRatingLabel: string
  makes: Makes[]
}

const HomeHero: React.FC<HeroProps> = ({
  image,
  heading,
  makes,
  totalStock,
  starRating,
  starRatingLabel,
  introFirstLine,
}) => {
  const {
    hero: { helpMeChoose },
  } = homePageLocales
  const { trackEvent } = useAnalytics()

  const sendHmcTrackEvent = () => {
    trackEvent({
      eventName: `Help me choose hero link`,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: 'click',
          category: 'link',
          label: `Help me choose hero link`,
          action: `Help me choose hero link link click`,
        },
      },
    })
  }

  return (
    <section className={styles['container']} aria-describedby="hero-heading">
      {/* This div is responsible for the gradient */}
      <div className={cx(styles['background'], styles['backgroundGradient'])} />

      <div className={styles['inner']}>
        <div className={styles['textContainer']}>
          <Text
            className={styles['heading']}
            element="h1"
            data-testid="hero-title"
          >
            {heading}
          </Text>
          <Text className={styles['intro']} element="h2">
            {introFirstLine}
          </Text>
        </div>
        <div className={styles['flexDirection']}>
          <div className={styles['imageContainer']}>
            <Image
              src={image.filename}
              alt={image.alt ?? ''}
              layout="fill"
              className={styles['heroImage']}
              priority
            />
          </div>

          <div className={styles['filtersHmcContainer']}>
            <div className={styles['filtersHmcInner']}>
              <Filters makes={makes} totalStock={totalStock} />

              <div className={styles['hmcContainer']}>
                <Text className={styles['hmcText']}>
                  <span>{helpMeChoose.mobile.paragraph1}</span>
                  <span>
                    {helpMeChoose.mobile.paragraph2}
                    <a
                      href={'/help-me-choose/most-important'}
                      className={styles['hmcLink']}
                      onClick={() => sendHmcTrackEvent()}
                    >
                      {helpMeChoose.mobile.link}
                    </a>
                  </span>
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TrustpilotOverview
        starRating={starRating}
        starRatingLabel={starRatingLabel}
      />
    </section>
  )
}

export default HomeHero
