import { Primary, SelectField } from '@cinch-labs/design-system'
import { formatNumber } from '@cinch-labs/shared-util'
import {
  EventNames,
  TrackingEventTypes,
  addDataDogUserAction,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import { useState } from 'react'

import { FieldOption, Makes, Models } from '../../../types/feature-home-page'
import styles from './filters.module.css'

export interface FiltersProps {
  totalStock: number
  makes: Makes[]
}

const modelToOptions = (models: Models[]): FieldOption[] =>
  models.map((model) => ({
    label: `${model.name} (${model.stock})`,
    value: model.name,
    count: `${model.stock}`,
  }))

const getSearchButtonText = (quantity: number): string =>
  quantity === 1 ? 'Search 1 car' : `Search ${quantity} cars`

export const Filters: React.FC<FiltersProps> = ({ totalStock, makes }) => {
  const { trackEvent } = useAnalytics()

  const [selectedMake, setSelectedMake] = useState('')
  const [selectedModel, setSelectedModel] = useState('')
  const [searchButtonText, setSearchButtonText] = useState(
    `Search all ${formatNumber(totalStock)} cars`,
  )

  const [modelOptions, setModelOptions] = useState<FieldOption[]>([])

  const handleOnChangeTracking = (id: string, value?: string) => {
    trackEvent({
      eventName:
        id === 'make' ? 'Homepage make dropdown' : 'Homepage model dropdown',
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: 'click',
          category: 'filter',
          action: 'select',
          label: id,
          value: value?.toLowerCase(),
        },
      },
    })
    addDataDogUserAction(`Select ${value} from ${id}s list`, {
      vehicle: {
        [id]: value,
      },
    })
  }

  const handleMakeFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const value = e.target.value === 'Any' ? undefined : e.target.value
    handleOnChangeTracking('make', value)
    if (value) {
      setSelectedMake(value)
      const make = makes.find((make) => make.name === value) as Makes
      setSearchButtonText(getSearchButtonText(make.stock))
      setModelOptions(modelToOptions(make.models))
    } else {
      setSelectedMake('')
      setSelectedModel('')
      setSearchButtonText(`Search all ${totalStock} cars`)
    }
  }

  const handleModelFilterChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const value = e.target.value === 'Any' ? undefined : e.target.value
    handleOnChangeTracking('model', value)
    if (value) {
      setSelectedModel(value)
      const model = makes
        .find((make) => make.name === selectedMake)
        ?.models.find((model) => model.name === value) as Models
      setSearchButtonText(getSearchButtonText(model.stock))
    } else {
      const make = makes.find((make) => make.name === selectedMake) as Makes
      setSearchButtonText(getSearchButtonText(make.stock))
      setSelectedModel('')
    }
  }

  const makeOptions: FieldOption[] = makes.map((make) => ({
    label: `${make.name} (${make.stock})`,
    value: make.name,
    count: `${make.stock}`,
  }))

  const handleOnSubmitTracking = () => {
    let trackEventName = 'Homepage filter search all cars'
    let actionName = ''

    if (selectedMake) {
      trackEventName = 'Homepage filter search now'
      actionName = selectedModel
        ? `${selectedMake},${selectedModel}`
        : `${selectedMake}`
    }
    trackEvent({
      eventName: trackEventName,
      type: TrackingEventTypes.ADOBE,
      data: {
        action: actionName,
        event: {
          name: 'submit',
          category: 'form',
          action: 'submit',
          label: 'search cars',
          value: trackEventName,
        },
      },
    })
  }

  const handleVehiclesFilterSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    handleOnSubmitTracking()
    e.preventDefault()

    window.location.assign(
      `/find-vehicle?make=${selectedMake ?? ''}&&selectedModel=${
        selectedModel ?? ''
      }`,
    )
  }

  const handleSearchButtonClickTracking = () => {
    trackEvent({
      eventName: 'Search all cars',
      type: TrackingEventTypes.ADOBE,
      data: {
        action: searchButtonText,
        event: {
          name: EventNames.click,
          category: 'button',

          label: 'search cars',
        },
      },
    })
  }

  return (
    <div className={styles['container']}>
      <form className={styles['form']} onSubmit={handleVehiclesFilterSubmit}>
        <SelectField
          label="Select make"
          labelHidden
          id="make"
          data-testid="make-select"
          options={makeOptions}
          placeholder={'Select make'}
          placeholderValue={'Any'}
          value={selectedMake}
          onChange={handleMakeFilterChange}
          classNames={{
            container: styles['selectContainer'],
            select: styles['select'],
            arrow: styles['arrow'],
          }}
          error={{ show: false }}
        />
        <div className={styles['selectDivide']} />
        <SelectField
          label="Select model"
          labelHidden
          id="model"
          data-testid="model-select"
          options={modelOptions}
          placeholder={'Select model'}
          placeholderValue={'Any'}
          value={selectedModel}
          onChange={handleModelFilterChange}
          disabled={!selectedMake}
          classNames={{
            container: styles['selectContainer'],
            select: styles['select'],
            arrow: styles['arrow'],
          }}
          error={{ show: false }}
        />
        <div className={styles['buttonContainer']}>
          <Primary
            type="submit"
            classNames={{ button: styles['button'] }}
            data-testid="searchButton"
            isFullWidth
            onClick={handleSearchButtonClickTracking}
          >
            {searchButtonText}
          </Primary>
        </div>
      </form>
    </div>
  )
}
