import { Secondary, SvgIcon, Text } from '@cinch-labs/design-system'
import { StoryblokImage } from '@cinch-nx/data-storyblok'
import { TrackingEventTypes, useAnalytics } from '@cinch-nx/shared-analytics'
import Wrapper from '@cinch-nx/shared-ui/lib/wrapper/wrapper'
import Image from 'next/image'
import { useCallback } from 'react'
import { Form } from 'react-final-form'

import FieldWrapper from './field-wrapper/field-wrapper.component'
import styles from './part-ex.module.css'
import TextFieldWrapper from './text-field-wrapper/text-field-wrapper.component'

export interface PartExchangeSectionProps {
  title: string
  subtitle: string
  label: string
  placeholder: string
  ctaText: string
  image: StoryblokImage
  link: string
}
const sanitiseVrm = (vrm: string) => vrm.replace(/\s/g, '')

export const partExchangeCalculatorId = 'part-exchange-calculator'
export const partExchangeContentPageUrl = '/part-exchange'
export const partExchangeCalculatorUrl = '/part-exchange/calculator'

const PartExchangeSection: React.FC<PartExchangeSectionProps> = ({
  title,
  subtitle,
  placeholder,
  label,
  image,
  link,
  ctaText,
}) => {
  const { trackEvent } = useAnalytics()

  const onCtaClick = useCallback((formData: { vrm: string }) => {
    const sanitisedVrm = sanitiseVrm(formData.vrm)

    const label = 'part-exchange-calculator'
    trackEvent({
      eventName: label,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: 'click',
          category: 'button',
          label,
          action: 'search',
        },
      },
    })

    window.location.href = `${partExchangeCalculatorUrl}?vrm=${sanitisedVrm}`
  }, [])

  return (
    <Wrapper className={{ container: styles.container }}>
      <section
        id={partExchangeCalculatorId}
        className={styles['partExchangeMarketingSectionContainer']}
      >
        <div
          id={partExchangeCalculatorId}
          className={styles['partExchangeMarketingSectionWrapper']}
        >
          <div className={styles['partExchangeMarketingSectionLeftSection']}>
            <div className={styles['partExchangeMarketingSectionContent']}>
              <Text
                element="h2"
                type="vehiclePrice"
                className={styles['partExchangeMarketingSectionTitle']}
              >
                {title}
              </Text>
              <Text
                element="h3"
                type="vehicleSubTitle"
                className={styles['partExchangeMarketingSectionSubTitle']}
              >
                {subtitle}
              </Text>

              <Form
                onSubmit={(formData: { vrm: string }) => onCtaClick(formData)}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={styles['vrmInputWrapper']}>
                      <FieldWrapper
                        id="vrmInput"
                        name="vrm"
                        required
                        component={TextFieldWrapper}
                        fullWidth={true}
                        aria-label={label}
                        classNames={{
                          label: { label: styles.vrmInputLabel },
                          input: styles.vrmInput,
                        }}
                        label={label}
                        placeholder={placeholder}
                        maxLength={10}
                      />
                      <Secondary type="submit" variant="alternative" href="">
                        {ctaText}
                      </Secondary>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
          <div className={styles['partExchangeMarketingSectionImage']}>
            <Image
              src={image.filename}
              alt={image.alt ?? ''}
              layout="responsive"
              width={410}
              height={362}
            />
          </div>
        </div>
      </section>
      <div className={styles.infoWrapper}>
        <a
          href="/part-exchange"
          className={styles.findMore}
          data-testid="part-exchange-button"
          onClick={() => {
            trackEvent({
              eventName: `Home page marketing block CTA`,
              type: TrackingEventTypes.ADOBE,
              data: {
                event: {
                  name: 'click',
                  category: 'button',
                  label: `Home page marketing block CTA`,
                  action: 'navigate to part-exchange',
                },
              },
            })
          }}
        >
          <SvgIcon name="info" height={20} color="toreaBay" />
          <span className={styles.findMoreButton}>{link}</span>
        </a>
      </div>
    </Wrapper>
  )
}

export default PartExchangeSection
