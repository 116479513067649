import {
  Carousel,
  CarouselControls,
  CarouselNavigation,
  CarouselPagination,
  CarouselSlide,
} from '@cinch-labs/design-system'

import OurCommitmentSlide from '../slides/our-commitment-slide'
import styles from './our-commitment-carousel.module.css'

export interface OurCommitmentCarouselProps {
  slides: OurCommitmentSlideProps[]
}

export interface OurCommitmentSlideProps {
  iconName: 'cash' | 'shield-heart' | 'check-circle'
  title: string
  body: string
}

const OurCommitmentCarousel: React.FC<OurCommitmentCarouselProps> = ({
  slides,
}) => {
  const backgroundColors = ['#8C32BE', '#5234B6', '#311C77']

  const carouselName = 'cinch-commitment'

  const navAriaLabels = {
    previous: 'Go to previous slide',
    next: 'Go to next slide',
  }

  const navCustomClasses = {
    prevButton: styles['prevButton'],
    nextButton: styles['nextButton'],
  }

  const swiperSettings = {
    loop: true,
  }

  return (
    <div data-testid="ourCommitment">
      <Carousel name={carouselName} swiperSettings={swiperSettings}>
        {slides.map((slide, idx) => (
          <CarouselSlide key={idx}>
            <OurCommitmentSlide
              {...slide}
              key={slide.title}
              backgroundColor={backgroundColors[idx % backgroundColors.length]}
            />
          </CarouselSlide>
        ))}
      </Carousel>
      <CarouselControls customClassNames={styles['controlsWrapper']}>
        <CarouselPagination
          name={carouselName}
          customClassNames={styles['customPagination']}
        />
        <CarouselNavigation
          name={carouselName}
          ariaLabels={navAriaLabels}
          customClassNames={navCustomClasses}
        />
      </CarouselControls>
    </div>
  )
}
export default OurCommitmentCarousel
