import api from './api'

interface Review {
  starRating: number
  title: string
  description: string
  customerName: string
  date: string
  link: string
}

export interface TrustpilotReviewsResponse {
  stars: number
  total: number

  starRatingLabel: string
  reviews: Review[]
}

const fallbackResponse: TrustpilotReviewsResponse = {
  total: 20_000,
  stars: 4.5,
  starRatingLabel: 'Excellent',
  reviews: [
    {
      starRating: 5,
      title: 'Really good experience',
      description:
        'Simple to navigate website, with good selection of cars and great part-exchange value.',
      customerName: 'Tom Willoughby',
      date: '6 August 2021',
      link: 'https://uk.trustpilot.com/review/cinch.co.uk',
    },
    {
      starRating: 5,
      title: 'Excellent service',
      description:
        'We bought a new car and also part exchanged our old one and the whole process was smooth and efficient.',
      customerName: 'Ms Martin',
      date: '8 September 2021',
      link: 'https://uk.trustpilot.com/review/cinch.co.uk',
    },
    {
      starRating: 5,
      title: 'So easy from start to finish',
      description:
        "Can't quite believe that I bought and part exchanged my car in less than a week with absolute ease.",
      customerName: 'Kim W',
      date: '23 August 2021',
      link: 'https://uk.trustpilot.com/review/cinch.co.uk',
    },
  ],
}

export const getReviews = (): Promise<TrustpilotReviewsResponse> =>
  api
    .request<TrustpilotReviewsResponse>({
      url: `/trustpilot-get-reviews`,
      method: 'GET',
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
      return fallbackResponse
    })
