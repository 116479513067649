import { TextField } from '@cinch-labs/design-system'
import { FC, FocusEvent, RefObject } from 'react'
import { FieldRenderProps } from 'react-final-form'

export interface TextFieldWrapperProps extends FieldRenderProps<string> {
  label?: string
  inputRef?: RefObject<HTMLInputElement>
}

const TextFieldWrapper: FC<TextFieldWrapperProps> = ({
  errorDisplay = 'realtime',
  input,
  meta,
  onBlur,
  tooltip,
  label,
  inputRef,
  ...rest
}) => (
  <TextField
    {...rest}
    label={label}
    id={rest['id']}
    name={input.name}
    type={input.type ? input.type : 'text'}
    value={input.value}
    onChange={input.onChange}
    onBlur={(e: FocusEvent<HTMLInputElement, Element>) => {
      input.onBlur(e)
      if (onBlur) onBlur(e)
    }}
    onFocus={input.onFocus}
    ref={inputRef}
  />
)

export default TextFieldWrapper
