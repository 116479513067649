import {
  compareObjects,
  cookieBuilder,
  getChannel,
  getCookie,
  getParamValue,
} from './shared-customer-journey-cookies.helper'
import {
  ClearCustomerJourneyCookieResult,
  Cookie,
  CustomerJourneyCookieResult,
} from './shared-customer-journey.types'

export const sharedCustomerJourneyCookies = (): Cookie => ({
  commissionGroup: getParamValue('parts') ?? 'DEFAULT',
  channel: getChannel(),
  awc: getParamValue('awc'),
  voucher: getParamValue('voucher') ?? '',
})

export const saveCustomerJourneyCookie = (): CustomerJourneyCookieResult => {
  if (typeof window !== 'undefined') {
    const hasCustomerConsented = !!getCookie({ name: 'cp' })
    const isAwcInQueryParameter = !!getParamValue('awc')
    const hasAWINCookie = !!getCookie({ name: 'awin' })

    if (hasCustomerConsented && isAwcInQueryParameter) {
      const buildCookieData = sharedCustomerJourneyCookies()

      document.cookie = cookieBuilder('awin', buildCookieData, 30)

      return { data: buildCookieData, message: 'Cookie successfully saved' }
    }

    if (hasCustomerConsented && !isAwcInQueryParameter && hasAWINCookie) {
      const oldCookie = JSON.parse(getCookie({ name: 'awin' }) as string)
      const newCookie = {
        ...oldCookie,
        channel: getChannel(),
      }

      if (compareObjects(oldCookie, newCookie)) {
        return { data: oldCookie, message: 'Cookie value not updated' }
      }

      document.cookie = cookieBuilder('awin', newCookie, 30)

      return { data: newCookie, message: 'Cookie successfully updated' }
    }

    if (!hasCustomerConsented) {
      return {
        data: null,
        message: 'cookie policy cookie is not present in cookies',
      }
    }

    return {
      data: null,
      message: 'unable to save cookie',
    }
  } else {
    return {
      data: null,
      message: 'window is undefined',
    }
  }
}

export const getCustomerJourneyCookie = (): CustomerJourneyCookieResult => {
  const awinCustomerJourneyCookie = getCookie({ name: 'awin' })

  const hasCustomerConsented = !!getCookie({ name: 'cp' })
  const hasCustomerJourney = !!awinCustomerJourneyCookie

  if (hasCustomerConsented && hasCustomerJourney) {
    return {
      data: JSON.parse(awinCustomerJourneyCookie),
      message: '',
    }
  }

  return {
    data: null,
    message: 'unable to get customer journey cookie',
  }
}

export const clearCustomerJourneyCookie =
  (): ClearCustomerJourneyCookieResult => {
    const hasCustomerJourney = !!getCookie({ name: 'awin' })

    if (hasCustomerJourney) {
      document.cookie = `awin=;expires=Thu, 01 Jan 1970 00:00:01 GMT`

      return {
        message: 'Cleared customer journey cookie',
      }
    }

    return {
      message: 'Unable to find customer journey cookie',
    }
  }
