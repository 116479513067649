import { Secondary, Text } from '@cinch-labs/design-system'
import { VehicleCard as SharedVehicleCard } from '@cinch-nx/landing-ui-shared/lib/vehicle-card/vehicle-card'
import {
  EventNames,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import type { Vehicle } from '@cinch-nx/shared-types'

import SearchIcon from '../assets/search.svg'
import styles from './cards.module.css'

export const FindSimilarVehiclesCard: React.FC = () => {
  const { trackEvent } = useAnalytics()

  const sendTrackEvent = () => {
    trackEvent({
      eventName: `Browse all cars`,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: 'click',
          category: 'recently-viewed',
          label: 'browse-all-cars',
          action: 'car-search-cta',
        },
      },
    })
  }
  return (
    <div className={styles['vehicleCardContainer']}>
      <article className={styles['findSimilarVehiclesCard']}>
        <div className={styles['textContainer']}>
          <SearchIcon />
          <Text
            className={styles['text']}
            fontWeight="bold"
            fontSize="xl"
            lineHeight="display"
          >
            Looking for something different?{' '}
          </Text>
        </div>
        <Secondary
          element="a"
          variant="alternative"
          classNames={{
            button: styles['button'],
          }}
          href="/find-vehicle"
          onClick={() => sendTrackEvent()}
        >
          Browse all cars
        </Secondary>
      </article>
    </div>
  )
}

interface VehicleCardProps {
  vehicle: Vehicle
  carouselName?: string
}

export const VehicleCard: React.FC<VehicleCardProps> = ({
  vehicle,
  carouselName,
}) => {
  const { trackEvent } = useAnalytics()
  const handleClick = () => {
    trackEvent({
      eventName: `${carouselName} - PDP Cards`,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: EventNames.click,
          category: carouselName,
          label: vehicle.vehicleId,
          action: 'Open',
        },
      },
    })
  }
  return (
    <div className={styles['vehicleCardContainer']}>
      <SharedVehicleCard
        key={vehicle.vehicleId}
        id={vehicle.vehicleId}
        isSaved={false}
        isPending={vehicle.isReserved}
        isAvailable={vehicle.isAvailable}
        vehicle={vehicle}
        isSmallCardSize
        onCardClick={handleClick}
        isPersonalised={true}
      />
    </div>
  )
}

interface VehicleCardsListSlideProps {
  vehicles: Vehicle[]
  carouselName?: string
}

export const VehicleCardsList: React.FC<VehicleCardsListSlideProps> = ({
  vehicles,
  carouselName,
}) => {
  return (
    <ul className={styles['vehicleCardList']} aria-label={carouselName}>
      {vehicles.map((vehicle) => (
        <li key={vehicle.vehicleId}>
          <VehicleCard
            key={vehicle.vehicleId}
            vehicle={vehicle}
            carouselName={carouselName}
          />
        </li>
      ))}
      {vehicles.length <= 2 ? (
        <li key="find-similar" className={styles['vehicleCardListItem']}>
          <FindSimilarVehiclesCard />
        </li>
      ) : null}
    </ul>
  )
}
