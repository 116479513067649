import { ComponentMap } from '@cinch-nx/data-storyblok'

import {
  HelpMeChoose,
  HomeHero,
  PartExchangeSection,
  ValuePropositions,
  WhyCinch,
} from '../components'

export const storyblokComponents: ComponentMap = {
  HomeHero: HomeHero,
  HelpMeChooseSection: HelpMeChoose,
  WhyCinchSection: WhyCinch,
  PartExSection: PartExchangeSection,
  ValuePropsCard: ValuePropositions,
}
