import { Text, TextStyles, designTokens } from '@cinch-labs/design-system'
import cx from 'classnames'

import Car from '../icons/assets/car.svg'
import SVG from '../svg'
import styles from './holding-image.module.css'

const { space } = designTokens.customProperties.ds

export const HoldingImage: React.FC<{
  text?: string
  isAvailable?: boolean
  className?: string
}> = ({ text = '', isAvailable, className = '' }) => (
  <div
    className={cx(styles.holdingImage, className, {
      [styles.holdingImageUnavailable]: !isAvailable,
    })}
  >
    <SVG
      className={styles.icon}
      children={Car}
      fill={'currentColor'}
      size={space['8-x']}
    />

    {text && <Text type={TextStyles.menuTitle}>{text}</Text>}
  </div>
)
