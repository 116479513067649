import { createContext, useContext } from 'react'

export interface TrustpilotScoreValues {
  starRatingLabel: string
  totalReviews: number
  starRating: number
}

const defaultValues: TrustpilotScoreValues = {
  starRatingLabel: 'Excellent',
  totalReviews: 10_000,
  starRating: 4.5,
}

export const TrustpilotScoreContext = createContext(defaultValues)

export function useTrustpilotScoreContext() {
  const context = useContext(TrustpilotScoreContext)

  if (context === undefined) {
    throw new Error('useTrustpilotScore must be used within a provider')
  }

  return context
}
