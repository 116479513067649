import FourAndHalfStars from './assets/stars-4.5.svg'
import FourStars from './assets/stars-4.svg'
import FiveStars from './assets/stars-5.svg'

export interface TrustpilotStarsProps {
  className?: string
  starRating: number
  starRatingLabel: string
}

const starSVGList: { [key: number]: JSX.Element } = {
  4: <FourStars />,
  4.5: <FourAndHalfStars />,
  5: <FiveStars />,
}

export function TrustpilotStars({
  className,
  starRating,
  starRatingLabel,
}: TrustpilotStarsProps) {
  const alt = `${starRatingLabel} rating on Trustpilot`

  return (
    <div className={className} data-testid="trustpilotStarsDefault">
      <svg aria-labelledby="starRating" viewBox="0 0 512 96">
        <title id="starRating">{alt}</title>
        {starSVGList[starRating] ?? <FiveStars />}
      </svg>
    </div>
  )
}

export default TrustpilotStars
