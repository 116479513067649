import {
  ComponentMap,
  SbBlokData,
  storyblokEditable,
} from '@cinch-nx/data-storyblok'

import { ElementFactory, checkNotOverLimit } from './util'

export interface StoryblokRendererProps {
  block: SbBlokData | SbBlokData[]
  components: ComponentMap
  props?: { [key: string]: unknown }
  componentsWithWrapper?: string[]
  limitedItems?: { [key: string]: number }
}

export function StoryblokRenderer({
  block,
  components,
  props,
  componentsWithWrapper,
  limitedItems,
}: StoryblokRendererProps) {
  if (Array.isArray(block)) {
    return (
      <>
        {block.map((blockElement, index) =>
          checkNotOverLimit(blockElement, limitedItems) ? (
            <div {...storyblokEditable(blockElement)} key={index}>
              {ElementFactory(
                blockElement,
                components,
                props,
                componentsWithWrapper,
              )}
            </div>
          ) : null,
        )}
      </>
    )
  }

  return (
    <div {...storyblokEditable(block)}>
      {ElementFactory(block, components, props)}
    </div>
  )
}
