import { Env, readFromEnv } from '@cinch-nx/environments'
import axios from 'axios'

export const api = axios.create({
  baseURL: readFromEnv(Env.TrustpilotUrl),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default api
