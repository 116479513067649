import { ContentSectionWithIcon } from '@cinch-labs/design-system'
import React from 'react'

import styles from './our-commitment-slide.module.css'

export interface OurCommitmentSlideProps {
  iconName: 'cash' | 'shield-heart' | 'check-circle'
  backgroundColor: string
  title: string
  body: string
}
const OurCommitmentSlide: React.FC<OurCommitmentSlideProps> = ({
  iconName,
  backgroundColor,
  title,
  body,
}) => (
  <div
    style={{ backgroundColor }}
    className={styles['ourCommitmentSlideContainer']}
  >
    <ContentSectionWithIcon
      sectionWrapperClass={styles['ourCommitmentSlide']}
      iconName={iconName}
      iconColor="goGreen"
      title={title}
      classNames={{ title: styles['title'], body: styles['body'] }}
    >
      <p dangerouslySetInnerHTML={{ __html: body }}></p>
    </ContentSectionWithIcon>
  </div>
)

export default OurCommitmentSlide
